
import { IGlossaryItem } from '@/common/types';
import { IManipulation, IManipulationType } from '@/modules/clinic/types';
import $app from '@/plugins/modules'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICuringPlan, IPatient, ITooth, IVisit } from '../types';

@Component
export default class CustomerCuringTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  appendProcessing = false;
  showArchived = false;
  lastPlan = 0;

  toothVisible = false;
  toothProcessing = false;
  toothSelected: ITooth | null = null;
  toothTab = 0;
  manipulationState: IManipulation | null = null;

  sortColumn: string | null = null;
  sortOrder: boolean = true;

  get teethUp(): Array<ITooth> {
    return this.patient && this.patient.teeth
      ? this.patient.teeth.filter(t => t.position === 'U')
      : [];
  }

  get teethDown(): Array<ITooth> {
    return this.patient && this.patient.teeth
      ? this.patient.teeth.filter(t => t.position === 'L')
      : [];
  }

  get plansList(): Array<ICuringPlan> {
    if (this.patient && this.patient.plans) {
      if (this.showArchived) {
        return this.patient.plans.filter(p => p.state === 'S');
      } else {
        return this.patient.plans.filter(p => p.state !== 'S');
      }
    }
    return [];
  }

  get plansGlossary(): Array<IGlossaryItem<number>> {
    return this.plansList.map(p => {
      return {
        code: p.id || 0,
        color: p.state === 'A' ? 'accent' : (p.state === 'S' ? 'secondary lighten-3' : 'primary lighten-3'),
        icon: p.state === 'A' ? 'fa-check' : (p.state === 'S' ? 'fa-file-archive' : 'fa-calendar-alt'),
        label: p.name
      }
    });
  }

  get plan(): ICuringPlan | null {
    if (this.patient && this.patient.selectedPlan) {
      return this.patient.plans.find(p => p.id === this.patient!.selectedPlan) || null;
    } else {
      return null;
    }
  }

  get planManipulations(): Array<IManipulation> {
    if (this.plan) {
      return this.plan.manipulations.sort((a, b) => {
        let stateCompare = 0;

        switch (this.sortColumn) {
          case 'state':
            stateCompare = this.stateToOrder(b.state) - this.stateToOrder(a.state);
            if (stateCompare === 0) {
              stateCompare = a.tooth - b.tooth;
              if (stateCompare === 0) {
                stateCompare = (!b.done ? 0 : new Date(b.done).getTime()) - (!a.done ? 0 : new Date(a.done).getTime());
              }
            }
            break;
          case 'date':
            stateCompare = (!b.done ? 0 : new Date(b.done).getTime()) - (!a.done ? 0 : new Date(a.done).getTime());
            if (stateCompare === 0) {
              stateCompare = this.stateToOrder(b.state) - this.stateToOrder(a.state);
              if (stateCompare === 0) {
                stateCompare = a.tooth - b.tooth;
              }
            }
            break;
          case 'tooth':
            stateCompare = a.tooth - b.tooth;
            if (stateCompare === 0) {
              stateCompare = this.stateToOrder(b.state) - this.stateToOrder(a.state);
              if (stateCompare === 0) {
                stateCompare = (!b.done ? 0 : new Date(b.done).getTime()) - (!a.done ? 0 : new Date(a.done).getTime());
              }
            }
            break;
        }

        return this.sortOrder ? stateCompare : -stateCompare;
      });
    }

    return [];
  }

  get visits(): Array<IVisit> {
    return !this.patient || !this.patient.visits
      ? []
      : this.patient.visits
        .filter(v => v.state === 'A')
        .sort((a, b) => {
          let aDif = new Date(a.timeFrom || new Date()).diffNow('s');
          let bDif = new Date(b.timeFrom || new Date()).diffNow('s');

          aDif = aDif < 0 ? Math.abs(aDif) - 43200 : aDif;
          bDif = bDif < 0 ? Math.abs(bDif) - 43200 : bDif;

          return aDif - bDif;
        });
  }

  get visitsGlossary(): Array<IGlossaryItem<number>> {
    return this.visits.map(p => {
      return {
        code: p.id || 0,
        color: 'primary',
        icon: 'fa-calendar-alt',
        label: !p.timeFrom ? '' : new Date(p.timeFrom).formatAlreadyStartsIn('already started', 'starts in', 'started now')
      }
    });
  }

  get selectedPlan(): number | null {
    return this.patient ? this.patient.selectedPlan : null;
  }

  set selectedPlan(value: number | null) {
    if (this.patient) {
      this.patient.selectedPlan = value || 0;
      this.loadPlan();
    }
  }

  get curingVisit(): number | null {
    if (this.patient) {
      if (this.patient.curingVisit === 0 && this.visits.length > 0) {
        this.patient.curingVisit = this.visits[0].id || 0;
      }
      return this.patient.curingVisit !== 0 ? this.patient.curingVisit : null;
    }
    return null;
  }

  set curingVisit(value: number | null) {
    if (this.patient) {
      this.patient.curingVisit = value || 0;
      this.loadPlan();
    }
  }

  get amount(): number {
    if (this.plan && this.plan.manipulations) {
      let amt = 0;
      this.plan.manipulations
        .filter(m => m.state === 'P')
        .forEach(m => { amt += m.price || 0 })
      return amt;
    } else {
      return 0;
    }
  }

  get noCompletedManipulations(): boolean {
    return this.planManipulations.every(m => m.state !== 'D')
  }

  async loadPlan() {
    if (this.patient && this.patient.selectedPlan && this.patient.selectedPlan !== this.lastPlan) {
      if (this.plan) {
        this.plan.processing = true;
        try {
          this.plan.manipulations = await $app.get('/api/patients/' + this.patient.patientId + '/curings/' + this.patient.selectedPlan + '/manipulations');
          this.lastPlan = this.patient.selectedPlan;
        } catch (error) {
          $app.pushError(error);
        }
        this.plan.processing = false;
      }
    }
  }

  async appendPlan() {
    if (this.patient) {
      this.appendProcessing = true;
      try {
        let plan: ICuringPlan = {
          id: null,
          name: '',
          state: 'P',
          patientId: this.patient!.patientId || 0,
          author: null,
          details: '',
          created: null,
          archived: null,
          manipulations: [],
          processing: false
        };
        plan = await $app.post('/api/patients/' + this.patient.patientId + '/curings', plan);

        this.patient.plans.push(plan);
        this.patient.selectedPlan = plan.id || 0;
      } catch (error) {
        $app.pushError(error);
      }
      this.appendProcessing = false;
    }
  }

  async storePlan() {
    if (this.plan && this.patient) {
      this.plan.processing = true;
      try {
        await $app.post('/api/patients/' + this.patient.patientId + '/curings', this.plan);
      } catch (error) {
        $app.pushError(error);
      }
      this.plan.processing = false;
    }
  }

  async archivePlan() {
    if (this.plan && this.patient) {
      this.plan.processing = true;
      try {
        this.plan.state = 'S';
        await $app.patch('/api/patients/' + this.patient.patientId + '/curings', this.plan);
      } catch (error) {
        $app.pushError(error);
      }
      this.plan.processing = false;
    }
  }

  async activatePlan() {
    if (this.plan && this.patient) {
      this.plan.processing = true;
      const oldState = this.plan.state;
      try {
        this.plan.state = 'A';
        await $app.patch('/api/patients/' + this.patient.patientId + '/curings', this.plan);
        this.patient.plans.forEach(m => {
          if (m.id !== this.plan!.id && m.state === 'A') {
            m.state = 'S';
          }
        });
      } catch (error) {
        this.plan.state = oldState;
        $app.pushError(error);
      }
      this.plan.processing = false;
    }
  }

  async storeManipulations(manipulations: Array<IManipulationType>) {
    if (this.plan && this.patient && this.toothSelected && manipulations) {
      this.toothProcessing = true;
      try {
        for (let i = 0; i < manipulations.length; i++) {
          const manipulation: IManipulation = {
            id: null,
            typeId: null,
            code: manipulations[i].code,
            name: null,
            state: 'P',
            visit: null,
            curing: this.plan.id || 0,
            tooth: this.toothSelected.number,
            price: null,
            done: null,
            doctor: null,
            surfaces: null,
            surfacesCount: null,
            editable: true,
            processed: false,
            processing: false
          }
          await $app.post('/api/patients/' + this.patient.patientId + '/curings/' + this.plan.id + '/manipulations', manipulation);
        }
        this.plan.manipulations = await $app.get('/api/patients/' + this.patient.patientId + '/curings/' + this.patient.selectedPlan + '/manipulations');
        this.toothVisible = false;
      } catch (error) {
        $app.pushError(error);
      }
      this.toothProcessing = false;
    }
  }

  async setManipulationState(manipulation: IManipulation, state: string) {
    if (this.plan && this.patient) {
      manipulation.processing = true;
      try {
        const edit: IManipulation = $app.clone(manipulation)
        edit.surfaces = null
        edit.state = state;
        edit.visit = this.curingVisit;

        const updated: IManipulation = await $app.patch('/api/patients/' + this.patient.patientId + '/curings/' + this.plan.id + '/manipulations', edit);

        manipulation.state = updated.state;
        manipulation.doctor = updated.doctor;
        manipulation.done = updated.done;
        manipulation.visit = updated.visit;
      } catch (error) {
        $app.pushError(error);
      }
      manipulation.processing = false;
    }
  }

  async setManipulationSurface(manipulation: IManipulation, surface: string) {
    if (this.plan && this.patient) {
      manipulation.processing = true;
      try {
        const edit: IManipulation = $app.clone(manipulation)

        if (edit.surfaces && edit.surfaces.includes(surface)) {
          edit.surfaces = edit.surfaces.replaceAll(surface, '')
        } else {
          edit.surfaces = (edit.surfaces || '') + surface
        }

        const updated: IManipulation = await $app.patch('/api/patients/' + this.patient.patientId + '/curings/' + this.plan.id + '/manipulations', edit);

        manipulation.surfaces = updated.surfaces
      } catch (error) {
        $app.pushError(error);
      }
      manipulation.processing = false;
    }
  }

  stateToOrder(state: string): number {
    if (state === 'W') {
      return 3
    } else if (state === 'P') {
      return 2
    } else if (state === 'D') {
      return 1
    } else {
      return 0
    }
  }

  updateSort(column: string) {
    if (this.sortColumn === column) {
      if (this.sortOrder) {
        this.sortOrder = false;
      } else {
        this.sortColumn = 'state';
        this.sortOrder = true;
      }
    } else {
      this.sortColumn = column;
      this.sortOrder = true;
    }
  }

  toggleShowArchived() {
    this.showArchived = !this.showArchived
  }

  open(tooth: ITooth) {
    this.toothSelected = tooth;
    this.toothTab = 0;
    this.toothVisible = true;
  }

  mounted() {
    this.updateSort('state');
  }

  async printCurePlan() {
    if (this.plan && this.patient) {
      window.open(window.location.origin + '/reports?report=CurePlanPrint&patient=' + this.patient.patientId + '&id=' + this.plan.id, '_blank');
    }
  }

  async delCurePlan(planId: number | null) {
    if (this.plan && this.patient) {
      try {
        await $app.delete('/api/patients/' + this.patient.patientId + '/curings/' + this.plan?.id + '/manipulations');
        this.patient.plans = this.patient.plans.filter(plan => plan.id !== planId);
        if (this.patient.selectedPlan === planId) {
          this.patient.selectedPlan = this.patient.plans.length > 0 ? this.patient.plans[0].id : null;
        }
      } catch (error) {
        $app.pushError(error);
      }
    }
  }
}
